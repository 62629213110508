<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="GLOBAL.queryRowGutter">
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="合同签署人">
              <a-input v-model="queryParam['%signor']"/>
            </a-form-item>
          </a-col>
          <a-col v-bind="GLOBAL.queryColSpan">
            <a-form-item label="合同编号">
              <a-input v-model="queryParam['%serial_num']"/>
            </a-form-item>
          </a-col>
          <a-col :xs="6">
            <span class="table-page-search-submitButtons">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
            </span>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="id"
      :columns="columns"
      :data="loadData">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="contract_files" slot-scope="text">
        <template v-for="(f, index) in text">
          <span v-if="index > 0" :key="'k' + f.uuid"> | </span>
          <span style="color: #00A0E9;cursor: pointer" :key="f.uuid" @click="downloadContract(f.uuid)">{{ f.name }}</span>
          <!--          <a :key="f.uuid" :href="GLOBAL.baseUrl + '/file/download?uuid=' + f.uuid">{{ f.name }} </a>-->
        </template>
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑归档编号</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="formVisible"
      :width="720"
      :maskClosable="false"
      title="编辑归档编号"
      @cancel="formVisible = false"
      @ok="handleSubmit">
      <a-form-model
        layout="vertical"
        :model="form"
        :rules="rules"
        ref="form">
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="归档编号" prop="filing_no">
              <a-input v-model="form.filing_no" placeholder="请输入归档编号" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import moment from 'moment';
import { getMmsContractPage, editLeaseInfo } from '@/api/mms'
import { downLoad } from '@/api/common'
export default {
  components: {
    STable
  },
   data() {
    return {
      queryParam: {},
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '所属部门',
          dataIndex: 'department_name'
        },
        {
          title: '合同签署人',
          dataIndex: 'signor'
        },
        {
          title: '合同编号',
          dataIndex: 'serial_num'
        },
        {
          title: '归档编号',
          dataIndex: 'filing_no'
        },
        {
          title: '签订日期',
          dataIndex: 'sign_date',
          width: 120,
          customRender: (text) => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '截止日期',
          dataIndex: 'end_date',
          width: 120,
          customRender: (text) => {
            return moment(text).format('YYYY/MM/DD')
          }
        },
        {
          title: '合同状态',
          dataIndex: 'contract_status',
          customRender: (text) => {
            switch (text) {
              case 0:
                return '未提交'
              case 1:
                return '审批中'
              case 2:
                return '已驳回'
              case 3:
                return '审批完成'
              case 5:
                return '已提交'
              case 4:
                return '已归档'
              case 6:
                return '已作废'
            }
          }
        },
        {
          title: '合同影印件',
          dataIndex: 'contract_files',
          scopedSlots: { customRender: 'contract_files' }
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          scopedSlots: { customRender: 'action' }
        }
      ],
      loadData: parameter => {
        return getMmsContractPage('lease_list_done', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      formVisible: false,
      form: {
        id: '',
        filing_no: ''
      },
      rules: {
        // filing_no: [{ required: true, message: '请输入归档编号', trigger: 'blur' }]
      }
    }
  },
  methods: {
    moment,
    downloadContract(uuid) {
      downLoad(uuid).then(res => {
        const data = res.data
        let fileName = ''
        const contentDisposition = res.headers['content-disposition']
        if (contentDisposition) {
          fileName = decodeURI(escape(contentDisposition.split('=')[1]))
        }
        const url = window.URL.createObjectURL(
          new Blob([data])
        )
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      });
    },
    handleEdit(record) {
      this.formVisible = true;
      // 表单赋值操作
      this.form.filing_no = record.filing_no;
      this.form.id = record.id;
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          editLeaseInfo(this.form).then(_ => {
            this.formVisible = false;
            this.$notification['success']({
              message: '提示',
              description: '编辑成功'
            });
            this.$refs.table.refresh(true);
          });
        } else {
          // 表单验证不通过
          return false;
        }
      });
    }
  }
}
</script>
